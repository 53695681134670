import Vue from 'vue'
import VueRouter from 'vue-router'
import GameNews from '../views/News.vue'
import GameMatches from '../views/Matches.vue'
import GameEvents from '../views/Events.vue'
import GameRankings from '../views/Rankings.vue'
import Search from '../views/Search.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: "/:game/news",
    name: "News",
    component: GameNews
  },
  {
    path: "/:game/matches",
    name: "Matches",
    component: GameMatches
  },
  {
    path: "/:game/events",
    name: "Events",
    component: GameEvents
  },
  {
    path: "/:game/rankings",
    name: "Rankings",
    component: GameRankings
  },
  {
    path: "/:game/search",
    name: "Search",
    component: Search
  }
];

const router = new VueRouter({
  routes
})

export default router
