<template>
  <main class="matches">
    <h1>Matches</h1>
    <section class="live">
      <div class="section-head">
        <h2>Live Matches</h2>
        <i class="ri-arrow-right-s-line"></i>
      </div>
      <!--<span class="no-content">No current live matches</span>-->
      <div class="content">
        <article class="match" v-for="n in 3" :key="n">
          <div class="team">
            <div class="logo"></div>
            <span class="name">Monarchy</span>
          </div>
          <div class="score">
            <span class="rounds"><span>1</span> - <span>0</span></span>
            <span class="maps"
              ><span class="winning">11</span> - <span>9</span></span
            >
            <div class="live">LIVE</div>
          </div>
          <div class="team">
            <div class="logo"></div>
            <span class="name">Team2</span>
          </div>
        </article>
      </div>
    </section>
    <section class="upcoming">
      <div class="section-head">
        <h2>Upcoming Matches</h2>
        <i class="ri-arrow-right-s-line"></i>
      </div>
      <!--<span class="no-content">No current upcoming matches</span>-->
      <div class="content">
        <article class="upcoming-match" v-for="n in 2" :key="n">
          <div class="teams">
            <div class="team">
              <div class="logo"></div>
              <span class="name">Team1</span>
            </div>
            <div class="team">
              <div class="logo"></div>
              <span class="name">Team1</span>
            </div>
          </div>
          <div class="info">
            <span class="date">15/10/21</span>
            <span class="time">18:00 CET</span>
          </div>
        </article>
      </div>
    </section>
    <section class="upcoming-matches">
      <div class="section-head">
        <h2>Results</h2>
        <i class="ri-arrow-right-s-line"></i>
      </div>
      <span class="no-content">No match results in record</span>
      <!--<div class="content">
        <article class="upcoming-match" v-for="n in 2" :key="n">
          <div class="teams">
            <div class="team">
              <div class="logo"></div>
              <span class="name">Team1</span>
            </div>
            <div class="team">
              <div class="logo"></div>
              <span class="name">Team1</span>
            </div>
          </div>
          <div class="info">
            <span class="date">15/10/21</span>
            <span class="time">18:00 CET</span>
          </div>
        </article>
      </div>-->
    </section>
  </main>
</template>

<script>
export default {
  data: () => ({
    live: [
      {
        id: 49846543,
      },
    ],
  }),
};
</script>