<template>
  <div id="body">
    <header>
      <div class="game-select">
        <img :src="gameData.icon" :alt="gameData.name" />
        <span>{{ gameData.name }}</span>
        <i class="ri-arrow-right-s-line"></i>
      </div>
      <div class="other">
        <router-link :to="`/${game}/search`">
          <i class="ri-search-2-line"></i>
        </router-link>
        <i class="ri-settings-3-line"></i>
        <i class="profile ri-user-3-line"></i>
      </div>
    </header>
    <router-view
      @setRankingTab="setRankingTab"
      :rankingTab="rankingTab"
      @setNewsTab="setNewsTab"
      :newsTab="newsTab"
    />
    <nav>
      <div class="wrap">
        <router-link :to="`/${game}/news`">
          <i class="ri-pages-line"></i>
        </router-link>
        <router-link :to="`/${game}/matches`">
          <i class="ri-medal-line"></i>
        </router-link>
        <router-link :to="`/${game}/events`">
          <i class="ri-calendar-event-line"></i>
        </router-link>
        <router-link :to="`/${game}/rankings`">
          <i class="ri-line-chart-line"></i>
        </router-link>
      </div>
      <a class="special">
        <i class="ri-trophy-line"></i>
      </a>
    </nav>
  </div>
</template>

<script>
export default {
  name: "App",
  data: () => ({
    game: "c-ops",
    games: [
      { name: "Critical Ops", slug: "c-ops", icon: "/imgs/games/c-ops.png" },
      { name: "CoD: Mobile", slug: "codm", icon: "/imgs/games/codm.png" },
    ],
    rankingTab: "teams",
    newsTab: "feed",
  }),
  computed: {
    gameData() {
      return this.games.find((obj) => obj.slug === this.game);
    },
    breakpoint() {
      return window.innerWidth;
    },
  },
  created() {
    this.checkGame(this.$route);
    if (this.$route.path === "/") {
      this.$router.push(`/${this.game}/news`);
    }
  },
  methods: {
    checkGame(to) {
      let games = this.games.map((o) => o.slug);
      let pathGame = to.path.split("/")[1];
      if (games.includes(pathGame)) this.game = pathGame;
    },
    setRankingTab(to) {
      this.rankingTab = to;
    },
    setNewsTab(to) {
      this.newsTab = to;
    },
  },
  watch: {
    $route(to) {
      this.checkGame(to);
    },
  },
};
</script>
