<template>
  <main class="news">
    <h1>News</h1>
    <section class="tab-select">
      <div class="but-wrap">
        <button
          @click="setTab('feed')"
          :class="newsTab == 'feed' ? 'active' : ''"
        >
          Feed
        </button>
        <button
          @click="setTab('articles')"
          :class="newsTab == 'articles' ? 'active' : ''"
        >
          Articles
        </button>
      </div>
    </section>
    <section v-show="newsTab == 'feed'" class="feed">
      <article v-for="f in feed" :key="f.id">
        <div class="text">
          <span class="text" v-html="f.text"></span>
          <span class="when" v-html="f.when"></span>
        </div>
        <div class="links">
          <router-link
            class="link"
            v-for="(l, i) in f.links"
            :key="`${f.id}-l-${i}`"
            :to="l.link"
          >
            <i :class="feedLinkIcon(l.type)"></i>
            <span v-html="l.text"></span>
          </router-link>
        </div>
      </article>
    </section>
    <section v-show="newsTab == 'articles'" class="articles">
      <article>
        <div class="banner">
          <div class="details">
            <span class="when">Yesterday</span>
            <div class="length">
              <i class="ri-time-line"></i>
              <span>5 minute read</span>
            </div>
          </div>
        </div>
        <div class="text">
          <span class="text"
            >Euphoria have won the semifinals of Circuit 2021</span
          >
          <router-link to="/article/2">READ</router-link>
        </div>
      </article>
    </section>
  </main>
</template>

<script>
export default {
  props: {
    newsTab: String,
  },
  data: () => ({
    feed: [
      {
        id: 2,
        text: "Euphoria has parted ways with Player1",
        when: "2 days ago",
        links: [
          {
            type: "player",
            link: "/player/1",
            text: "Player1",
          },
          {
            type: "team",
            link: "/team/1",
            text: "Euphoria",
          },
        ],
      },
      {
        id: 1,
        text: "NoobTeam has presented their new roster",
        when: "3 days ago",
        links: [
          {
            type: "team",
            link: "/team/2",
            text: "NoobTeam",
          },
        ],
      },
    ],
  }),
  methods: {
    setTab(tab) {
      this.$emit("setNewsTab", tab);
    },
    feedLinkIcon(type) {
      switch (type) {
        case "team":
          //return "ri-group-fill";
          return "ri-sword-fill";
        case "player":
          return "ri-user-3-fill";
      }
    },
  },
};
</script>