<template>
  <main class="search">
    <h1>Search</h1>
    <div class="input">
      <input type="text" v-model="searchInput" />
      <button>
        <i class="ri-search-line"></i>
      </button>
    </div>
  </main>
</template>

<script>
export default {
  data: () => ({
    searchInput: "",
  }),
};
</script>

<style>
</style>