<template>
  <main class="rankings">
    <h1>{{ titleTab }} ranking</h1>
    <section class="tab-select">
      <div class="but-wrap">
        <button
          @click="setTab('teams')"
          :class="rankingTab == 'teams' ? 'active' : ''"
        >
          TEAMS
        </button>
        <button
          @click="setTab('players')"
          :class="rankingTab == 'players' ? 'active' : ''"
        >
          PLAYERS
        </button>
      </div>
    </section>
    <section v-if="rankingTab === 'teams'" class="teams">
      <div class="place" v-for="(n, i) in this.teams" :key="'t'+i">
        <span class="position">{{i+1}}.</span>
        <span v-if="n.progress == 'up'" class="progress">
          <svg xmlns="http://www.w3.org/2000/svg">
            <g>
              <path id="svg_1" d="m1.8125,17.16406l8.1875,-14.32811l8.1875,14.32811l-16.37499,0z" stroke="#2ed573" fill="#2ed573"/>
            </g>
          </svg>
        </span>
        <span v-if="n.progress == 'none'" class="progress">
          <svg xmlns="http://www.w3.org/2000/svg">
            <g>
              <rect rx="1" id="svg_1" height="2.96774" width="16.51613" y="8.51613" x="1.74194" stroke="#1E90FF" fill="#1E90FF"/>
            </g>
          </svg>
        </span>
        <span v-if="n.progress == 'down'" class="progress">
          <svg xmlns="http://www.w3.org/2000/svg">
            <g>
              <path id="svg_1" transform="rotate(-180 10 10)" d="m1.8125,17.16406l8.1875,-14.32811l8.1875,14.32811l-16.37499,0z" stroke="#FF4757" fill="#FF4757"/>
            </g>
          </svg>
        </span>
        <span class="name">{{n.name}}</span>
        <span class="rating">{{n.rating}}</span>
      </div>
    </section>
    <section v-else-if="rankingTab === 'players'" class="players">
      <div class="place" v-for="(n, i) in this.players" :key="'p'+i">
        <span class="position">{{i+1}}.</span>
        <span v-if="n.progress == 'up'" class="progress">
          <svg xmlns="http://www.w3.org/2000/svg">
            <g>
              <path id="svg_1" d="m1.8125,17.16406l8.1875,-14.32811l8.1875,14.32811l-16.37499,0z" stroke="#2ed573" fill="#2ed573"/>
            </g>
          </svg>
        </span>
        <span v-if="n.progress == 'none'" class="progress">
          <svg xmlns="http://www.w3.org/2000/svg">
            <g>
              <rect rx="1" id="svg_1" height="2.96774" width="16.51613" y="8.51613" x="1.74194" stroke="#1E90FF" fill="#1E90FF"/>
            </g>
          </svg>
        </span>
        <span v-if="n.progress == 'down'" class="progress">
          <svg xmlns="http://www.w3.org/2000/svg">
            <g>
              <path id="svg_1" transform="rotate(-180 10 10)" d="m1.8125,17.16406l8.1875,-14.32811l8.1875,14.32811l-16.37499,0z" stroke="#FF4757" fill="#FF4757"/>
            </g>
          </svg>
        </span>
        <span class="name">{{n.name}}</span>
        <span class="rating">{{n.rating}}</span>
      </div>
    </section>

  </main>
</template>

<script>
export default {
  props: {
    rankingTab: String,
  },
  methods: {
    setTab(tab) {
      this.$emit("setRankingTab", tab);
    },
  },
  computed: {
    titleTab() {
      return (
        this.rankingTab[0].toUpperCase() +
        this.rankingTab.slice(1, this.rankingTab.length - 1)
      );
    },
  },
  data: () => ({
    players: [
      {"name": "Player1", "progress": "up", "rating": "90"},
      {"name": "Player2", "progress": "none", "rating": "50"},
      {"name": "Player3", "progress": "down", "rating": "35"},
    ],
    teams: [
      {"name": "Team1", "progress": "up", "rating": "180"},
      {"name": "Team2", "progress": "none", "rating": "100"},
      {"name": "Team3", "progress": "down", "rating": "50"},
    ]
  }),
};
</script>